<template>
    <header>
        <div class="header-top">
            <div class="contact-info">
                <div class="contact-info-text">
                    Propreté et Brillance, Partout.
                </div>
                <div class="contact-info-links">
                    <a href="mailto:splashautobm60@gmail.com" class="contact-link">
                        <span class="text"><ion-icon name="mail"></ion-icon> splashautobm60@gmail.com</span>
                    </a>
                    <a href="tel:0952719683" class="contact-link">
                        <span class="text"><ion-icon name="call"></ion-icon>09 52 71 96 83</span>
                    </a>
                    <a href="tel:0649579466" class="contact-link">
                        <span class="text"><ion-icon name="call-sharp"></ion-icon> Commercial : 06 49 57 94 66</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="header-bottom">
            <div class="home-nav">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="Splash Auto BM" />
                </div>
                <nav class="navigation">
                    <ul>
                        <li><router-link to="/">Accueil</router-link></li>
                        <li>
                            <a href="#services" @click="scrollToServices">Prestations</a>
                        </li>
                        <li v-if="isAuthenticated">
                            <router-link to="/profile">{{ user.firstName }}</router-link>
                        </li>
                        <li v-else>
                            <router-link to="/login-register">Connexion</router-link>
                        </li>
                    </ul>
                </nav>
                <div class="contact-appointment">
                    <router-link to="/contact">
                        <button>Nous contacter</button>
                    </router-link>
                    <router-link to="/appointment">
                        <button class="button-appointment">Prendre RDV</button>
                    </router-link>
                </div>
                <div class="bottom-bar"></div>
            </div>
        </div>
        <div class="header-bottom-mobile">
            <div class="home-nav-mobile">
                <div class="logo">
                    SPLASH AUTO BM
                </div>
                <button id="navMobile">
                    <div class="middle-bar"></div>
                </button>
            </div>
            <nav class="mobile-navigation" id="mobileNav">
                <ul>
                    <li><a href="/" @click="scrollToHome">Accueil</a></li>
                    <li><a href="#services" @click="scrollToServices">Prestations</a></li>
                    <li><router-link to="/map-hours">Où et Quand Nous Trouver</router-link></li>
                    <li><router-link to="/contact">Nous contacter</router-link></li>
                    <li v-if="isAuthenticated">
                        <router-link to="/profile">{{ user.firstName }}</router-link>
                    </li>
                    <li v-else>
                        <router-link to="/login-register">Connexion</router-link>
                    </li>
                    <li>
                        <router-link to="/appointment">
                            Prendre RDV
                        </router-link>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/splashauto_bm/" target="_blank">
                            Instagram</a>
                    </li>
                    <li>
                        <a href="https://www.snapchat.com/add/splashauto60" target="_blank">
                            Snapchat</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, computed, nextTick, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'HeaderComponent',
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const lastScrollTop = ref(0);

        const isAuthenticated = computed(() => store.state.isAuthenticated);
        const user = computed(() => store.state.user);

        watch([isAuthenticated, user], () => {
            console.log('isAuthenticated:', isAuthenticated.value);
            console.log('user:', user.value);
        });

        function scrollToServices(event) {
            event.preventDefault();
            if (route.path !== '/') {
                router.push({ path: '/', hash: '#services' });
            } else {
                nextTick(() => {
                    setTimeout(() => {
                        const element = document.getElementById('services');
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                        resetMenu();
                    }, 100);
                });
            }
        }

        function scrollToHome(event) {
            event.preventDefault();
            if (route.path !== '/') {
                router.push('/');
            } else {
                resetMenu();
                nextTick(() => {
                    setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }, 100);
                });
            }
        }

        function handleRouteChange(to) {
            if (to.hash) {
                nextTick(() => {
                    setTimeout(() => {
                        const element = document.querySelector(to.hash);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 100);
                });
            }
            resetMenu();
        }

        function toggleMenu() {
            const navMobile = document.getElementById('navMobile');
            const mobileNav = document.getElementById('mobileNav');
            navMobile.classList.toggle('close');
            mobileNav.classList.toggle('show');
        }

        function handleScroll() {
            const currentScrollTop = window.scrollY;
            const header = document.querySelector('header');
            const mobileNav = document.getElementById('mobileNav');
            const navMobile = document.getElementById('navMobile');
            const threshold = document.documentElement.scrollHeight * 0.20;

            if (currentScrollTop > threshold) {
                header.classList.add('header-reduced');
            } else {
                header.classList.remove('header-reduced');
            }

            if (currentScrollTop > lastScrollTop.value) {
                if (currentScrollTop > threshold) {
                    header.classList.add('header-hidden');
                }
                // Retarder la fermeture du menu mobile jusqu'à ce que l'utilisateur ait fini de faire défiler
                setTimeout(() => {
                    if (mobileNav.classList.contains('show')) {
                        mobileNav.classList.remove('show');
                        navMobile.classList.remove('close');
                    }
                }, 500); // Vous pouvez ajuster ce délai selon vos besoins
            } else {
                header.classList.remove('header-hidden');
            }

            lastScrollTop.value = currentScrollTop;
        }

        function resetMenu() {
            const navMobile = document.getElementById('navMobile');
            const mobileNav = document.getElementById('mobileNav');
            if (navMobile && mobileNav) {
                navMobile.classList.remove('close');
                mobileNav.classList.remove('show');
            }
        }

        function adjustBodyPadding() {
            const header = document.querySelector('header');
            if (header) {
                const headerHeight = header.offsetHeight;
                document.body.style.paddingTop = `${headerHeight}px`;
            }
        }

        onMounted(() => {
            adjustBodyPadding();
            window.addEventListener('resize', adjustBodyPadding);
            window.addEventListener('scroll', handleScroll);

            const navMobile = document.getElementById('navMobile');
            if (navMobile) {
                navMobile.addEventListener('click', toggleMenu);
            }

            watch(() => route.path, () => {
                handleRouteChange(route);
            });
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', adjustBodyPadding);
            window.removeEventListener('scroll', handleScroll);

            const navMobile = document.getElementById('navMobile');
            if (navMobile) {
                navMobile.removeEventListener('click', toggleMenu);
            }
        });

        return {
            scrollToServices,
            scrollToHome,
            isAuthenticated,
            user,
        };
    },
};
</script>

<style>
/* Styles de base pour l'en-tête */
header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.header-reduced {
    transform: translateY(-100%);
    transition: transform 0.3s ease;
}

/* Styles pour cacher le menu mobile */
.header-hidden {
    transform: translateY(-100%);
}

/* Styles pour la partie supérieure de l'en-tête */
.header-top {
    display: flex;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    background-color: var(--yellow-color);
    color: var(--white-color);
    position: sticky;
    z-index: 2;
}

.contact-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 75rem;
    margin: 0 auto;
    gap: 1.25rem;
    font-weight: bold;
}

.contact-info-text {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
}

.contact-info-links {
    display: flex;
    gap: 0.9375rem;
}

.contact-link {
    color: var(--white-color);
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-link:hover {
    color: var(--white-color);
}

span.text {
    display: flex;
    align-items: center;
}

ion-icon {
    margin-right: 0.3125rem;
}

/* Styles pour la partie inférieure de l'en-tête */
.header-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    background: var(--black-color);
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: bold;
    overflow: hidden;
}

.header-bottom::before {
    content: "";
    position: absolute;
    top: 2.25rem;
    left: 20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.header-bottom::after {
    content: "";
    position: absolute;
    top: 20px;
    left: calc(20% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

/* Styles pour la barre inférieure */
.bottom-bar {
    width: 100%;
    height: 0.3125rem;
    background: var(--yellow-color);
    position: absolute;
    top: 8.85rem;
    z-index: 2;
}

.header-bottom-mobile {
    display: none;
}

.home-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.logo {
    color: var(--yellow-color);
}

.logo img {
    height: 3.75rem;
}

.navigation ul {
    list-style: none;
    display: flex;
    gap: 1.25rem;
    margin: 0;
    padding: 0;
    position: relative;
}

.navigation ul li {
    position: relative;
}

.navigation ul li a {
    color: var(--white-color);
    text-decoration: none;
    padding: 0.3125rem 0;
    display: inline-block;
}

.navigation ul li a:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.125rem;
    background: var(--yellow-color);
    transition: width 0.3s ease;
}

.navigation ul li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0.125rem;
    background: var(--yellow-color);
    transition: width 0.3s ease;
}

.navigation,
.contact-appointment {
    margin-top: 0.8rem;
}

.contact-appointment a {
    color: var(--white-color);
    margin-left: 1.25rem;
}

button {
    background-color: var(--black-color);
    color: var(--white-color);
    border-style: solid;
    border-color: var(--white-color);
    padding: 0.3125rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}

.button-appointment {
    background-color: var(--yellow-color);
    border-style: solid;
    border-color: var(--yellow-color);
}

.button-appointment:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
}

/* Styles responsive */
@media (max-width: 1024px) {
    .contact-info {
        flex-direction: row;
        align-items: center;
    }

    .contact-info-text {
        display: none;
    }

    .contact-info-links {
        flex-direction: row;
    }

    .navigation ul {
        font-size: .75rem;
        gap: 1rem;
        padding-left: 2.5rem;
    }
}

@media (max-width: 768px) {
    .header-top {
        display: none;
    }

    .header-bottom {
        display: none;
    }

    .header-bottom-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        background-color: var(--black-color);
        color: var(--white-color);
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
    }

    .home-nav-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .home-nav-mobile .logo {
        border: 2px solid var(--yellow-color);
        padding: 0.5rem;
        text-align: center;
        display: inline-block;
    }

    #navMobile {
        height: 3rem;
        width: 3rem;
        position: relative;
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #navMobile::before,
    #navMobile::after,
    #navMobile .middle-bar {
        content: '';
        position: absolute;
        background-color: var(--white-color);
        height: 0.2rem;
        width: 2rem;
        transition: all 0.3s ease;
    }

    #navMobile::before {
        top: 26%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #navMobile .middle-bar {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    #navMobile::after {
        bottom: 26%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Styles pour le bouton burger en mode fermé */
    #navMobile.close::before {
        transform: translate(-50%, -50%) rotate(45deg);
        top: 51%;
    }

    #navMobile.close .middle-bar {
        opacity: 0;
    }

    #navMobile.close::after {
        transform: translate(-50%, -50%) rotate(-45deg);
        bottom: 44%;
    }

    /* Styles pour la navigation mobile cachée par défaut */
    .mobile-navigation {
        display: none;
        background-color: var(--black-color);
        color: var(--white-color);
        text-transform: uppercase;
        font-weight: 700;
        padding: 0;
        max-height: 48vh;
        overflow-y: auto;
        opacity: 0;
        transition: height .3s ease, opacity .3s ease, padding .3s ease;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1000;
    }

    /* Styles pour afficher la navigation mobile lorsqu'elle est active */
    .mobile-navigation.show {
        display: block;
        height: fit-content;
        padding: 0.625rem 0;
        opacity: 1;
    }

    .mobile-navigation ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .mobile-navigation ul li {
        padding: 0.625rem 0;
    }

    .mobile-navigation ul li a {
        color: var(--white-color);
        text-decoration: none;
        display: block;
    }

    .mobile-navigation ul li a:hover {
        color: var(--yellow-color);
    }
}

@media (max-width: 480px) {
    .logo img {
        height: 3.125rem;
    }

    button {
        padding: 0.25rem;
        font-size: 0.875rem;
    }

    .contact-info-text {
        font-size: 0.875rem;
    }

    .contact-info-links {
        font-size: 0.875rem;
    }

    .header-bottom {
        font-size: 0.875rem;
    }
}
</style>
